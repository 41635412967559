<template>
  <div class="color-option-wrapper" :class="{ mobile: isMobile, tablet: isTablet }">
    <div
    class="color-option"
    :class="[option.color, { selected: option.color === pickedColor }]"
    v-for="(option, index) in colorOptions"
    :key="index"
    @click="$emit('colorChange', option.color)"></div>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
    isTablet: Boolean,
    colorOptions: Array,
    pickedColor: String
  }
}
</script>

<style lang="scss" scoped>
.color-option-wrapper {
  display: flex;
  padding-left: 20px;
  .color-option {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    border-radius: 50%;
    cursor: pointer;
    &.first-highlight-color {
      background-color: palette(baseColors, highlightColor);
    }

    &.second-highlight-color {
      background-color: palette(baseColors, secondHighlightColor);
    }

    &.third-highlight-color {
      background-color: palette(baseColors, thirdHighlightColor);
    }

    &.selected {
      border: 2px solid #fff;
    }
  }

  &.mobile,
  &.tablet {
    width: 200px;
    justify-content: space-around;
  }
}
</style>
