<template>
  <div class="contacts-form-wrapper" :class="{ mobile: isMobile, tablet: isTablet }">
    <form ref="form" class="contacts-form" @submit.prevent="sendEmail">
      <div class="form-row">
        <div class="form-group">
          <label class="label" :class="{ 'invalid-name': invalidName }" for="name">{{$t('contacts.nameLabel')}}</label>
          <input
          class="input"
          :class="{ 'invalid-name': invalidName }"
          id="name"
          type="text"
          name="name"
          minlength="1"
          maxlength="30"
          autocomplete="off"
          :placeholder="$t('contacts.namePlaceholder')"
          v-model="name"
          v-maska="{ mask: 'N*', tokens: {'N': { pattern: /[a-zA-Z0-9À-ž ]/ } }}"
          @focus="invalidName = ''">
        </div>
        <div class="form-group">
          <label class="label" :class="{ 'invalid-email': invalidEmail }" for="email">{{$t('contacts.emailLabel')}}</label>
          <input
          class="input"
          :class="{ 'invalid-email': invalidEmail }"
          id="email"
          type="email"
          name="email"
          maxlength="252"
          autocomplete="off"
          :placeholder="$t('contacts.emailPlaceholder')"
          v-model="email"
          v-maska="{ mask: 'M*', tokens: {'M': { pattern: /[0-9A-Za-z._@]/ } }}"
          @focus="invalidEmail = ''">
        </div>
      </div>

      <div class="form-group">
        <label class="label" :class="{ 'invalid-subject': invalidSubject }" for="subject">{{$t('contacts.subjectLabel')}}</label>
        <input
        class="input"
        :class="{ 'invalid-subject': invalidSubject }"
        id="subject"
        type="text"
        name="subject"
        minlength="1"
        maxlength="120"
        autocomplete="off"
        :placeholder="$t('contacts.subjectPlaceholder')"
        v-model="subject"
        @focus="invalidSubject = ''">
      </div>

      <div class="form-group">
        <label class="label" :class="{ 'invalid-message': invalidMessage }" for="message">{{$t('contacts.messageLabel')}}</label>
        <textarea
        class="textarea"
        :class="{ 'invalid-message': invalidMessage }"
        id="message"
        name="message"
        cols="30"
        rows="5"
        :placeholder="$t('contacts.messagePlaceholder')"
        v-model="message"
        @focus="invalidMessage = ''"></textarea>
      </div>

      <span class="mandatory-fields">{{$t('contacts.mandatoryFields')}}</span>

      <app-button
      :isSubmit="true"
      :isMobile="isMobile"
      :isTablet="isTablet"
      :pickedColor="pickedColor"
      @invalidInput="invalidInputHandler"/>
    </form>
  </div>
</template>

<script>
import Button from '@/components/generic/buttons/Button'
import emailjs from 'emailjs-com'
export default {
  props: {
    pickedColor: String,
    isMobile: Boolean,
    isTablet: Boolean
  },
  components: {
    'app-button': Button
  },
  data () {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      invalidName: false,
      invalidEmail: false,
      invalidSubject: false,
      invalidMessage: false
    }
  },
  methods: {
    emailValidator () {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regex.test(this.email)
    },
    invalidInputHandler () {
      if (this.name === '') {
        this.invalidName = true
      }
      if (this.email === '' || !this.emailValidator()) {
        this.invalidEmail = true
      }
      if (this.subject === '') {
        this.invalidSubject = true
      }
      if (this.message === '') {
        this.invalidMessage = true
      }
    },
    sendEmail () {
      if (this.invalidName || this.invalidEmail || !this.emailValidator() || this.invalidSubject || this.invalidMessage) return

      try {
        emailjs.sendForm(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
          this.$refs.form,
          process.env.VUE_APP_EMAILJS_USER_ID, {
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message
          })
      } catch (err) {
        console.log(err)
      } finally {
        this.name = ''
        this.email = ''
        this.subject = ''
        this.message = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts-form-wrapper {
  flex: 1 65%;
  .contacts-form {
    display: flex;
    flex-direction: column;
    .form-row {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .form-group {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .label {
        @include label-styles;
        color: palette(genericColors, whiteColor);
        &.invalid-name,
        &.invalid-email,
        &.invalid-subject,
        &.invalid-message {
          color: palette(errorColors, error);
        }
      }
      .input[type=text],
      .input[type=email],
      .textarea {
        padding: 5px 10px;
        border: 1px solid palette(genericColors, lightGreyColor);
        border-radius: 4px;
        outline: none;

        &.invalid-name,
        &.invalid-email,
        &.invalid-subject,
        &.invalid-message {
          border: 2px solid palette(errorColors, error);
        }
      }
    }

    .mandatory-fields {
      @include secondary-font;
      margin-bottom: 20px;
      font-size: $p_small_size;
    }
  }

  &.mobile {
    .contacts-form {
      .form-row {
        gap: unset;
      }
      .mandatory-fields {
        font-size: $p_mobile_small_size;
      }
    }
  }

  &.mobile,
  &.tablet {
    margin-top: 20px;
  }
}
</style>
