<template>
  <div class="skills-wrapper">
    <h3 class="section-subtitle" :class="{ mobile: isMobile }">
      {{$t('about.skills.titleFirstPhrase')}}
      <span class="subtitle-completion">{{$t('about.skills.titleCompletion')}}</span>
    </h3>
    <div class="skills-info-wrapper" :class="{ mobile: isMobile }">
      <div div class="skill" :class="{ mobile: isMobile }" v-for="(skill, index) in skills" :key="index">
        <div class="skill-info">
          <h3 class="skill-label">{{skill.label}}</h3>
          <span class="skill-text" :class="pickedColor" :style="{'right': 'calc(' + percentageCalc(skill.percentage) + ' - 23px)'}">{{skill.percentage + '%'}}
            <div class="arrow"></div>
          </span>
        </div>
        <div class="skill-bar">
          <div class="skill-percentage" :class="pickedColor" :style="{'width': skill.percentage + '%'}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
    pickedColor: String
  },
  data () {
    return {
      skills: [
        { label: 'HTML', percentage: 70 },
        { label: 'CSS', percentage: 70 },
        { label: 'Javascript', percentage: 60 },
        { label: 'Vue', percentage: 60 },
        { label: 'Bootstrap', percentage: 50 },
        { label: 'JQuery', percentage: 40 },
        { label: 'PHP', percentage: 40 },
        { label: 'MySQL', percentage: 30 }
      ]
    }
  },
  methods: {
    percentageCalc (percentage) {
      return (100 - percentage) + '%'
    }
  }
}
</script>

<style lang="scss" scoped>
.skills-wrapper {
  @include subsection-style;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .section-subtitle {
    margin-bottom: 50px;
    font-weight: $font_lighter;
    .subtitle-completion {
      font-weight: $font_bolder;
    }

    &.mobile {
      font-size: $h3_mobile_size;
    }
  }
  .skills-info-wrapper {
    @include secondary-font;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    :nth-child(odd) {
      padding-right: 30px;
    }

    .skill {
      width: 50%;
      margin-bottom: 30px;
      .skill-info {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding: 0;
        .skill-label,
        .skill-text {
          font-size: $detail_text_regular_size;
        }
        .skill-text {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          height: 32px;
          border: 1px solid palette(baseColors, highlightColor);
          border-radius: 4px;
          font-size: $detail_text_small_size;
          font-weight: $font_light;
          text-align: center;
          transition: border .2s ease-in-out;

          &.second-highlight-color {
            border: 1px solid palette(baseColors, secondHighlightColor);
            .arrow {
              border: 1px solid palette(baseColors, secondHighlightColor);
              border-width: 0 1px 1px 0;
            }
          }

          &.third-highlight-color {
            border: 1px solid palette(baseColors, thirdHighlightColor);
            .arrow {
              border: 1px solid palette(baseColors, thirdHighlightColor);
              border-width: 0 1px 1px 0;
            }
          }

          .arrow {
            position: absolute;
            left: 36px;
            display: inline-block;
            padding: 4px;
            transform: translateY(33.5px) rotate(45deg) skew(3deg, 3deg) translateX(-27px);
            bottom: 8px;
            border: 1px solid palette(baseColors, highlightColor);
            border-width: 0 1px 1px 0;
            background-color: palette(baseColors, mainBackground);
            transition: border .2s ease-in-out;
          }
        }
      }
      .skill-bar {
        height: 10px;
        background-color: palette(textColors, lightGreyColor);
        .skill-percentage {
          height: 100%;
          background-color: palette(baseColors, highlightColor);
          transition: background .2s ease-in-out;
          &.second-highlight-color {
            background-color: palette(baseColors, secondHighlightColor);
          }

          &.third-highlight-color {
            background-color: palette(baseColors, thirdHighlightColor);
          }
        }
      }

      &.mobile {
        margin-bottom: 40px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &.mobile {
      flex-direction: column;
      flex-wrap: nowrap;
      .skill {
        width: 100%;
        padding: 0;
      }
    }
  }
}
</style>
