<template>
  <section id="about" class="about-section">
    <div class="section-wrapper about" :class="{ mobile: isMobile, tablet: isTablet }">
      <div class="title-wrapper">
        <h2 class="section-title title-first-phrase">
          {{$t('about.titleFirstPhrase').toUpperCase() }}
          <span class="title-completion">{{$t('about.titleCompletion').toUpperCase()}}</span>
        </h2>
        <animated-bar :pickedColor="pickedColor"/>
      </div>

      <div class="info-wrapper" :class="{ mobile: isMobile, tablet: isTablet }">
        <div class="info-pic" :class="pickedColor">
          <div class="image-container">
            <img class="my-pic" :src="myPic" alt="">
          </div>
        </div>
        <div class="info-text" :class="{ mobile: isMobile, tablet: isTablet }">
          <h3 class="section-subtitle">
            {{$t('about.moreInfo.titleFirstPhrase')}}
            <span class="subtitle-completion">{{$t('about.moreInfo.titleCompletion')}}</span>
          </h3>
          <p class="info-paragraph">{{$t('about.infoText')}}</p>
          <ul class="personal-info-list">
            <li class="personal-info-entry" v-for="(info, index) in personalInfo" :key="index">
              <span class="entry-label">{{info.label}}</span>
              {{info.value}}
            </li>
          </ul>

          <download-button v-if="isMobile || isTablet" :isMobile="isMobile" :isTablet="isTablet" :pickedColor="pickedColor"/>
        </div>
      </div>

      <app-separator :isMobile="isMobile"/>

      <my-skills :isMobile="isMobile" :pickedColor="pickedColor"/>

      <app-separator :isMobile="isMobile"/>

      <my-experience :isMobile="isMobile" :pickedColor="pickedColor"/>
    </div>
  </section>
</template>

<script>
import Separator from '@/components/generic/separators/Separator'
import Skills from '@/components/about/Skills'
import Experience from '@/components/about/Experience'
import DownloadButton from '@/components/generic/buttons/DownloadButton'
import AnimatedBar from '@/components/generic/animatedBar/AnimatedBar'
import MyPic from '@/assets/images/about.jpg'
export default {
  props: {
    isMobile: Boolean,
    isTablet: Boolean,
    pickedColor: String
  },
  components: {
    'app-separator': Separator,
    'my-skills': Skills,
    'my-experience': Experience,
    'download-button': DownloadButton,
    'animated-bar': AnimatedBar
  },
  data () {
    return {
      myPic: MyPic,
      personalInfo: [
        { label: 'Nome: ', value: 'João' },
        { label: 'Apelido: ', value: 'Mira' },
        { label: 'Data de Nasc.: ', value: '28/03/1984' },
        { label: 'Nacionalidade: ', value: 'Portuguesa' },
        { label: 'Naturalidade: ', value: 'Lisboa' },
        { label: 'Email: ', value: 'joao.mira01@gmail.com' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.about-section {
  @include section-style;
  .section-title {
    font-weight: $font_more_bolder;
    .title-completion {
      font-weight: $font_lighter;
    }
  }
}
.info-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 400px;
  .info-pic {
    height: 100%;
    box-shadow: 10px 10px 0 palette(baseColors, highlightColor);
    transition: box-shadow .2s ease-in-out;

    &.second-highlight-color {
      box-shadow: 10px 10px 0 palette(baseColors, secondHighlightColor);
    }

    &.third-highlight-color {
      box-shadow: 10px 10px 0 palette(baseColors, thirdHighlightColor);
    }

    .image-container {
      position: relative;
      display: block;
      width: 300px;
      height: 100%;
      margin: 0 auto;

      .my-pic {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .info-text {
    width: 100%;
    padding-left: 60px;
    line-height: $line_height_desktop;

    .section-subtitle {
      @include secondary-font;
      margin-bottom: 30px;
      font-size: $h3_size;
      font-weight: $font_lighter;
      .subtitle-completion {
        font-weight: $font_bolder;
      }
    }

    .info-paragraph {
      margin-bottom: 20px;
      white-space: pre-line;
    }

    .personal-info-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      .personal-info-entry {
        width: 50%;
        margin-bottom: 10px;
        font-size: $detail_text_regular_size;
        .entry-label {
          font-weight: $font_bolder;
        }
      }
    }

    &.mobile,
    &.tablet {
      .personal-info-list {
        flex-direction: column;
      }
    }

    &.mobile {
      padding: 0;
      .info-paragraph {
        font-size: $p_mobile_size;
        line-height: $line_height_mobile;
      }
      .personal-info-list {
        .personal-info-entry {
          width: 100%;
          font-size: $detail_text_mobile_small_size;
        }
      }
    }

    &.tablet {
      padding: 0;
      .info-paragraph {
        font-size: $p_tablet_size;
      }
      .personal-info-list {
        .personal-info-entry {
          width: 100%;
          font-size: $detail_text_tablet_regular_size;
        }
      }
    }
  }

  &.mobile,
  &.tablet {
    flex-direction: column;
    align-items: center;
    height: auto;
    .info-pic {
      margin-bottom: 60px;
      .image-container {
        width: 240px;
        height: 240px;
        .my-pic {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &::before {
          display: none;
        }
      }
    }

    .info-text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
