import Vue from 'vue'
import i18n from './i18n'
import VueTypedJs from 'vue-typed-js'
import vClickOutside from 'v-click-outside'
import Maska from 'maska'

/* App */
import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(VueTypedJs)
Vue.use(vClickOutside)
Vue.use(Maska)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
