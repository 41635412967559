<template>
  <div v-if="show" class="back-to-top-wrapper" @click="scrollHandler($event, 'home')">
    <a class="back-to-top-link" :class="pickedColor" href="#home">
      <app-icon icon="arrow-up"/>
    </a>
  </div>
</template>

<script>
import Icon from '@/components/generic/icons/Icon'
import { smoothScroll } from '@/assets/helperFunctions/helpers'
export default {
  props: {
    show: Boolean,
    pickedColor: String
  },
  components: {
    'app-icon': Icon
  },
  methods: {
    scrollHandler (e, el) {
      smoothScroll(e, el)
    }
  }
}
</script>

<style lang="scss" scoped>
.back-to-top-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  .back-to-top-link {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: palette(baseColors, transparencyHighlightColor);

    &.second-highlight-color {
      background-color: palette(baseColors, transparencySecondHighlightColor);
    }

    &.third-highlight-color {
      background-color: palette(baseColors, transparencyThirdHighlightColor);
    }
  }
}
</style>
