export const addMobileQuerie = (prop, size) => {
  return window.matchMedia(`screen and (${prop}:${size}px)`)
}

export const addTabletQuerie = (minWidth, minSize, maxWidth, maxSize) => {
  return window.matchMedia(`screen and (${minWidth}:${minSize}px) and (${maxWidth}:${maxSize}px)`)
}

export const smoothScroll = (e, el) => {
  document.getElementById(el).scrollIntoView({
    behavior: 'smooth'
  })

  e.preventDefault()
}
