<template>
  <div class="intro-wrapper" :class="{ mobile: isMobile, tablet: isTablet }">
    <div class="intro-text-wrapper">
      <h2 class="welcome-text" :class="{ mobile: isMobile, tablet: isTablet }">{{$t('intro.welcome')}}</h2>
      <vue-typed-js
      :strings="[$t('intro.stringOne'), $t('intro.stringTwo'), $t('intro.stringThree')]"
      :typeSpeed="100"
      :loop="true"
      :backSpeed="50"
      :backDelay="2000">
        <h1 class="intro-typing" :class="[{ mobile: isMobile, tablet: isTablet }, pickedColor]">{{$t('intro.staticString') }}
          <span class="typing" :class="{ mobile: isMobile, tablet: isTablet }"></span>
        </h1>
      </vue-typed-js>
    </div>
    <div class="icons-and-button-wrapper" :class="{ mobile: isMobile, tablet: isTablet }">
      <div class="icons-wrapper">
        <a class="intro-icon-link" href="https://www.linkedin.com/in/jmira84/" target="_blank">
          <linkedin-icon class="intro-icon"/>
        </a>
        <a class="intro-icon-link" href="https://github.com/JMira84" target="_blank">
          <github-icon class="intro-icon"/>
        </a>
      </div>

      <download-button v-if="!(isMobile || isTablet)" :pickedColor="pickedColor"/>
    </div>
    <a class="arrow-icon" :class="{ mobile: isMobile }" href="#about" @click="scrollHandler($event, 'about')">
      <app-arrow/>
    </a>
  </div>
</template>

<script>
import Linkedin from '@/assets/icons/linkedin-icon.svg?inline'
import Github from '@/assets/icons/github-icon.svg?inline'
import DownloadButton from '@/components/generic/buttons/DownloadButton'
import Arrow from '@/assets/icons/arrow-icon.svg?inline'
import { smoothScroll } from '@/assets/helperFunctions/helpers'
export default {
  props: {
    isMobile: Boolean,
    isTablet: Boolean,
    pickedColor: String
  },
  components: {
    'linkedin-icon': Linkedin,
    'github-icon': Github,
    'download-button': DownloadButton,
    'app-arrow': Arrow
  },
  methods: {
    scrollHandler (e, el) {
      smoothScroll(e, el)
    }
  }
}
</script>

<style lang="scss" scoped>
.intro-wrapper {
  @include secondary-font;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc(100vh - 100px);
  padding: 40px 40px 0 40px;
  color: palette(textColors, whiteColor);

  .intro-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    padding-bottom: 80px;
    .welcome-text {
      margin-bottom: 5px;
      font-size: $h2_size;
      font-weight: $font_light;
    }
    .intro-typing {
      margin-bottom: 150px;
      font-size: 65px;
      font-weight: $font_lighter;
      letter-spacing: 0.1rem;

      :last-child {
        color: palette(baseColors, highlightColor);
        transition: color .2s ease-in-out;
      }
      .typing, :last-child {
        font-weight: $font_bolder;
      }

      &.second-highlight-color {
        :last-child {
          color: palette(baseColors, secondHighlightColor);
        }
      }

      &.third-highlight-color {
        :last-child {
          color: palette(baseColors, thirdHighlightColor);
        }
      }
    }
  }

  .icons-and-button-wrapper {
    position: absolute;
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icons-wrapper {
      display: flex;

      .intro-icon-link {
        padding: 8px;
        color: palette(textColors, whiteColor);
        .intro-icon {
          width: 37px;
          height: 37px;
        }
      }
    }
  }

  .arrow-icon {
    position: absolute;
    bottom: 0;
    color: palette(textColors, whiteColor);
    animation: pulse 2s infinite linear;
    cursor: pointer;

    &.mobile {
      margin-bottom: 50px;
    }
  }

  &.mobile {
    padding: 40px 20px 0 20px;
    .intro-text-wrapper {
      .welcome-text {
        margin-bottom: 15px;
        font-size: 20px;
      }
      .intro-typing {
        margin-bottom: 50px;
        font-size: 25px;
        .typing {
          font-size: 25px;
        }
      }
    }
    .icons-and-button-wrapper {
      margin-top: 20px;
      .icons-wrapper {
        .intro-icon-link {
          .intro-icon {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  &.tablet {
    .intro-text-wrapper {
      .welcome-text {
        font-size: 30px;
      }
      .intro-typing {
        margin-bottom: 80px;
        font-size: 43px;
        .typing {
          font-size: 43px;
        }
      }
    }
    .icons-and-button-wrapper {
      margin-top: 20px;
      .icons-wrapper {
        .intro-icon-link {
          .intro-icon {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }

  @keyframes pulse {
    0% {
      bottom: 0;
    }
    50% {
      bottom: 10px;
    }
    100% {
      bottom: 0;
    }
  }
}
</style>
