<template>
    <header class="header" :class="{ 'header-change': headerChange, tablet: isTablet }" v-click-outside="closeMenu">
        <img class="header-logo" :src="logo"/>

        <div class="hamburger" :class="{ 'hamburger-transform': showMenu }" @click="showMenu = !showMenu">
            <div class="top"></div>
            <div class="middle"></div>
            <div class="bottom"></div>
        </div>

        <transition name="slide">
          <nav class="navigation" v-if="showMenu">
            <ul class="menu-list">
              <li class="menu-option" v-for="(entry, index) in menuEntries" :key="index">
                <a class="option-link" :href="entry.to" @click="scrollHandler($event, entry.to)">{{entry.label}}</a>
              </li>
              <li class="menu-color-options">
                <color-picker
                :isMobile="isMobile"
                :isTablet="isTablet"
                :colorOptions="colorOptions"
                :pickedColor="pickedColor"
                @colorChange="$emit('colorChange', $event)"/>
              </li>
            </ul>
          </nav>
        </transition>
    </header>
</template>

<script>
import ColorPicker from '@/components/generic/colorPicker/ColorPicker'
import { smoothScroll } from '@/assets/helperFunctions/helpers'
export default {
  props: {
    menuEntries: Array,
    colorOptions: Array,
    logo: String,
    isMobile: Boolean,
    isTablet: Boolean,
    headerChange: Boolean,
    pickedColor: String
  },
  components: {
    'color-picker': ColorPicker
  },
  data () {
    return {
      showMenu: false
    }
  },
  methods: {
    closeMenu () {
      this.showMenu = false
    },
    scrollHandler (e, el) {
      smoothScroll(e, el)
      this.closeMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  @include header-styles;
  padding: 20px 20px;

  &.tablet {
    padding: 20px 40px;
  }

  .navigation {
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    .menu-list {
      display: flex;
      flex-direction: column;
      background-color: #131919;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      .menu-option {
        .option-link {
          @include secondary-font;
          display: block;
          padding: 20px 40px;
          color: palette(textColors, whiteColor);
          font-weight: $font_light;
        }
      }
      .menu-color-options {
        padding: 20px 0;
      }
    }
  }

  &.header-change {
    background-color: #131919;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  .hamburger {
    cursor: pointer;
    .top,
    .middle,
    .bottom {
        width: 30px;
        margin: 5px 0;
        border-bottom: 3px solid palette(genericColors, whiteColor);
        -webkit-transition: all 300ms cubic-bezier(.175, .885, .32, 1.275);
            -o-transition: all 300ms cubic-bezier(.175, .885, .32, 1.275);
                transition: all 300ms cubic-bezier(.175, .885, .32, 1.275);
    }

    .middle {
        -webkit-transition: scale .3s linear;
            -o-transition: scale .3s linear;
                transition: scale .3s linear;
    }

    &.hamburger-transform {
      .top {
          -webkit-transform: translate(-1px, 7px) rotate(135deg);
              -ms-transform: translate(-1px, 7px) rotate(135deg);
                  transform: translate(-1px, 7px) rotate(135deg);
      }

      .middle { transform: scale(0); }

      .bottom {
          -webkit-transform: translate(-1px, -8px) rotate(-135deg);
              -ms-transform: translate(-1px, -8px) rotate(-135deg);
                  transform: translate(-1px, -8px) rotate(-135deg);
      }
    }
  }
  .slide-enter-active,
  .slide-leave-active {
      transition: transform 0.2s ease;
  }

  .slide-enter,
  .slide-leave-to {
      transform: translateX(-100%);
      transition: all 150ms ease-in 0s;
  }
}
</style>
