<template>
  <main class="main">
    <app-intro :isMobile="isMobile" :isTablet="isTablet" :pickedColor="pickedColor"/>
    <app-about :isMobile="isMobile" :isTablet="isTablet" :pickedColor="pickedColor"/>
    <app-contacts :isMobile="isMobile" :isTablet="isTablet" :pickedColor="pickedColor"/>
  </main>
</template>

<script>
import Intro from '@/components/intro/Intro'
import About from '@/components/about/About'
import Contacts from '@/components/contacts/Contacts'
export default {
  props: {
    isMobile: Boolean,
    isTablet: Boolean,
    pickedColor: String
  },
  components: {
    'app-intro': Intro,
    'app-about': About,
    'app-contacts': Contacts
  }
}
</script>

<style lang="scss" scoped>
</style>
