<template>
  <div class="button-wrapper" :class="{ submit: isSubmit, mobile: isMobile, tablet: isTablet }">
      <input
      v-if="isSubmit"
      class="input"
      :class="[pickedColor, { 'has-hover': !(isMobile || isTablet) }]"
      type="submit"
      value="Enviar Mensagem"
      @click="$emit('invalidInput')">
    </div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
    isTablet: Boolean,
    isSubmit: Boolean,
    pickedColor: String
  }
}
</script>

<style lang="scss" scoped>
.button-wrapper {
  &.submit {
    align-self: flex-end;
    .input[type=submit] {
      @include secondary-font;
      padding: 15px;
      border: none;
      color: palette(textColors, whiteColor);
      background-color: palette(baseColors, highlightColor);
      cursor: pointer;
      transition: all .2s ease-in-out;
      &.has-hover {
        &:hover {
          color: palette(baseColors, highlightColor);
          background-color: palette(genericColors, whiteColor);
        }
        &.second-highlight-color {
          background-color: palette(baseColors, secondHighlightColor);
          &:hover {
            color: palette(baseColors, secondHighlightColor);
            background-color: palette(genericColors, whiteColor);
          }
        }
        &.third-highlight-color {
          background-color: palette(baseColors, thirdHighlightColor);
          &:hover {
            color: palette(baseColors, thirdHighlightColor);
            background-color: palette(genericColors, whiteColor);
          }
        }
      }

      &.second-highlight-color {
        background-color: palette(baseColors, secondHighlightColor);
      }

      &.third-highlight-color {
        background-color: palette(baseColors, thirdHighlightColor);
      }
    }
  }

  &.mobile {
    width: 100%;
    .input[type=submit] {
      width: 100%;
    }
  }
}
</style>
