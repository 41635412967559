<template>
  <div class="experience-wrapper">
    <h3 class="section-subtitle" :class="{ mobile: isMobile }">
      {{$t('about.experience.titleFirstPhrase')}}
      <span class="subtitle-completion">{{$t('about.experience.titleCompletion')}}</span>
    </h3>
    <div class="work-and-education" :class="{ mobile: isMobile }">
      <work-and-studies
      icon="graduation-cap"
      :contentData="studies"
      :isMobile="isMobile"
      :pickedColor="pickedColor"/>
      <work-and-studies
      icon="briefcase"
      :contentData="workExperience"
      :isMobile="isMobile"
      :pickedColor="pickedColor"/>
    </div>
  </div>
</template>

<script>
import WorkAndStudies from '@/components/about/experience/WorkAndStudies'
export default {
  props: {
    isMobile: Boolean,
    pickedColor: String
  },
  components: {
    'work-and-studies': WorkAndStudies
  },
  data () {
    return {
      studies: [
        { time: '2019 - 2020', title: 'Curso Profissional', subtitle: 'FLAG', text: 'Curso de Full-Stack Web Development.' },
        { time: '2009 - 2010', title: 'Pós-Graduação', subtitle: 'IADE', text: 'Pós-Graduação em Publicidade.' },
        { time: '2005 - 2008', title: 'Licenciatura', subtitle: 'Universidade Lusófona', text: 'Licenciatura em Comunicação Aplicada: Marketing, Publicidade e Relações Públicas.' }
      ],
      workExperience: [
        { time: '2020 - 2021', title: 'Front-end Web Developer', subtitle: 'Innovation Makers', text: 'Em 2020 iniciei a minha primeira experiência como Front-end Web Developer na Innovation Makers.' },
        { time: '2014 - 2016', title: 'Copywriter', subtitle: 'DDB Portugal', text: 'Em 2014 dei continuidade ao meu percurso na DDB Portugal.' },
        { time: '2010 - 2014', title: 'Copywriter', subtitle: 'Havas Portugal', text: 'O meu percurso profissional começou na Havas Portugal, em 2010, como copywriter publicitário.' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.experience-wrapper {
  @include subsection-style;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .section-subtitle {
    margin-bottom: 50px;
    font-weight: $font_lighter;
    .subtitle-completion {
      font-weight: $font_bolder;
    }

    &.mobile {
      font-size: $h3_mobile_size;
    }
  }

  .work-and-education {
    display: flex;
    flex-direction: row;
    width: 100%;

    &.mobile {
      flex-direction: column;
    }
  }

}
</style>
