<template>
  <section id="contacts" class="contact-section">
    <section-separator :isMobile="isMobile" :isTablet="isTablet" :pickedColor="pickedColor"/>
    <div class="section-wrapper" :class="{ mobile: isMobile, tablet: isTablet }">
      <div class="title-wrapper">
        <h2 class="section-title title-first-phrase">
          {{$t('contacts.titleFirstPhrase').toUpperCase() }}
          <span class="title-completion">{{$t('contacts.titleCompletion').toUpperCase()}}</span>
        </h2>
        <animated-bar :pickedColor="pickedColor"/>
      </div>
      <div class="content-wrapper" :class="{ mobile: isMobile, tablet: isTablet }">
        <div class="contacts-info-wrapper">
          <h3 class="section-subtitle contacts-subtitle">{{$t('contacts.subtitle').toUpperCase()}}</h3>
          <p class="contacts-text">{{$t('contacts.contactText')}}</p>
          <div class="contact-detail-wrapper">
            <div class="contact-detail" v-for="(detail, index) in contactDetails" :key="index">
              <app-icon class="detail-icon" :class="pickedColor" :icon="detail.icon"/>
              <div class="contact-detail-info">
                <span class="detail-label" :class="pickedColor">{{detail.label}}</span>
                <span class="detail-text">{{detail.text}}</span>
              </div>
            </div>
          </div>
        </div>
        <contacts-form :pickedColor="pickedColor" :isMobile="isMobile" :isTablet="isTablet"/>
      </div>
    </div>
  </section>
</template>

<script>
import AnimatedBar from '@/components/generic/animatedBar/AnimatedBar'
import SectionSeparator from '@/components/generic/separators/SectionSeparator'
import Form from '@/components/contacts/ContactsForm'
import Icon from '@/components/generic/icons/Icon'
export default {
  props: {
    isMobile: Boolean,
    isTablet: Boolean,
    pickedColor: String
  },
  components: {
    'animated-bar': AnimatedBar,
    'section-separator': SectionSeparator,
    'contacts-form': Form,
    'app-icon': Icon
  },
  data () {
    return {
      contactDetails: [
        { label: 'Telefone', text: '933 189 813', icon: 'phone' },
        { label: 'Email', text: 'joao.mira01@gmail.com', icon: 'email' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-section {
  @include section-style;
  background-color: palette(baseColors, secondaryBackground);
  .section-title {
    font-weight: $font_more_bolder;
    .title-completion {
      font-weight: $font_lighter;
    }
  }
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .contacts-info-wrapper {
      padding-right: 30px;
      @include subsection-style;
      flex: 1 35%;
      display: flex;
      flex-direction: column;
      .contacts-subtitle {
        margin-bottom: 10px;
      }
      .contacts-text {
        margin-bottom: 20px;
        line-height: $line_height_desktop;
      }
      .contact-detail-wrapper {
        .contact-detail {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .detail-icon {
            color: palette(baseColors, highlightColor);
            transition: color .2s ease-in-out;
            &.second-highlight-color {
              color: palette(baseColors, secondHighlightColor);
            }

            &.third-highlight-color {
              color: palette(baseColors, thirdHighlightColor);
            }
          }
          .contact-detail-info {
            display: flex;
            flex-direction: column;
            padding-left: 15px;
            .detail-label {
              font-weight: $font_bold;
              color: palette(baseColors, highlightColor);
              transition: color .2s ease-in-out;
              &.second-highlight-color {
                color: palette(baseColors, secondHighlightColor);
              }

              &.third-highlight-color {
                color: palette(baseColors, thirdHighlightColor);
              }
            }
          }
        }
      }
    }

    &.mobile {
      .contacts-info-wrapper {
        .contacts-text {
          font-size: $p_mobile_size;
          line-height: $line_height_mobile;
        }
        .contact-detail-wrapper {
          .contact-detail {
            .contact-detail-info {
              .detail-label,
              .detail-text {
                font-size: $p_mobile_size;
              }
            }
          }
        }
      }
    }
  }
}
</style>
