<template>
  <div ref="container" class="app-container" :class="{ mobile: isMobile }" @scroll="scrollHandler">
    <div id="home"></div>
    <app-header
    v-if="!(isMobile || isTablet)"
    :menuEntries="menuEntries"
    :colorOptions="colorOptions"
    :logo="logo"
    :headerChange="headerChange"
    :pickedColor="pickedColor"
    @colorChange="pickedColor = $event"/>
    <app-mobile-header
    v-else
    :menuEntries="menuEntries"
    :colorOptions="colorOptions"
    :logo="logo"
    :headerChange="headerChange"
    :pickedColor="pickedColor"
    :isMobile="isMobile"
    :isTablet="isTablet"
    @colorChange="pickedColor = $event"/>
    <app-content :isMobile="isMobile" :isTablet="isTablet" :pickedColor="pickedColor"/>
    <app-back-to-top :show="showBackToTop" :pickedColor="pickedColor"/>
  </div>
</template>

<script>
import Header from '@/components/header/Header'
import MobileHeader from '@/components/header/MobileHeader'
import Content from '@/views/Content'
import Logo from '@/assets/logo/logo2.png'
import BackToTop from '@/components/generic/buttons/BackToTopButton/'
import { addMobileQuerie, addTabletQuerie } from '@/assets/helperFunctions/helpers'
export default {
  components: {
    'app-header': Header,
    'app-mobile-header': MobileHeader,
    'app-content': Content,
    'app-back-to-top': BackToTop
  },
  data () {
    return {
      isMobile: false,
      isTablet: false,
      headerChange: false,
      showBackToTop: false,
      logo: Logo,
      menuEntries: [
        { label: this.$t('menu.home'), to: 'home' },
        { label: this.$t('menu.about'), to: 'about' },
        { label: this.$t('menu.contact'), to: 'contacts' }
      ],
      colorOptions: [
        { color: 'first-highlight-color' },
        { color: 'second-highlight-color' },
        { color: 'third-highlight-color' }
      ],
      pickedColor: 'first-highlight-color'
    }
  },
  methods: {
    updateMobileSize (isMobile) { this.isMobile = isMobile },
    updateTabletSize (isTablet) { this.isTablet = isTablet },
    scrollHandler () {
      this.$refs.container.scrollTop >= 100 ? this.headerChange = true : this.headerChange = false
      this.$refs.container.scrollTop >= 650 ? this.showBackToTop = true : this.showBackToTop = false
    }
  },
  mounted () {
    /* for mobile */
    const widthMobileQueries = addMobileQuerie('max-width', '575')
    const widthTabletQueries = addTabletQuerie('min-width', '575.1', 'max-width', '991')

    /* here we call them manually on mounted, to guaratee the styles are correct */
    this.updateMobileSize(widthMobileQueries.matches)
    this.updateTabletSize(widthTabletQueries.matches)
    /* attach listeners , so it updates with responsive */
    widthMobileQueries.addListener((x) => { this.updateMobileSize(x.matches) })
    widthTabletQueries.addListener((x) => { this.updateTabletSize(x.matches) })
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  background-image: url(../assets/images/intro_pic.jpg);
  background-position: center center;
  background-size: cover;
  background-clip: content-box;
  background-attachment: fixed;
  background-color: #b5b5b5;
  background-blend-mode: multiply;

  overflow: auto;
  overflow-x: hidden;

  scrollbar-color: palette(genericColors, whiteColor) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    margin-top: 4px;
    background-color: transparent;
    background-clip: content-box;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-clip: padding-box;
    background-color: palette(genericColors, whiteColor);
  }

  #home {
    position: absolute;
    top: 0;
  }
}
</style>
