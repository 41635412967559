<template>
  <component :is="iconComponent"/>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    iconComponent () {
      return () => import('@/assets/icons/' + this.icon + '-icon' + '.svg?inline')
    }
  }
}
</script>
