<template>
  <div class="work-and-studies-wrapper" :class="{ mobile: isMobile }">
    <ul class="work-and-studies-list">
      <li class="work-and-studies-entry" :class="pickedColor" v-for="(content, index) in contentData" :key="index">
        <div class="work-and-studies-icon">
          <app-icon :icon="icon"/>
        </div>
        <span class="time">{{content.time}}</span>
        <h5 class="title">
          {{content.title.toUpperCase()}}
          <span class="title-complement">{{content.subtitle.toUpperCase()}}</span>
        </h5>
        <p class="text">{{content.text}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@/components/generic/icons/Icon'
export default {
  props: {
    icon: String,
    contentData: Array,
    isMobile: Boolean,
    pickedColor: String
  },
  components: {
    'app-icon': Icon
  }
}
</script>

<style lang="scss" scoped>
.work-and-studies-wrapper {
  width: 50%;
  .work-and-studies-list {
    .work-and-studies-entry {
      position: relative;
      padding: 0 20px 0 60px;
      margin: 0 0 50px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 20px;
        bottom: 0;
        border-left: 1px solid palette(baseColors, highlightColor);
        transition: border .2s ease-in-out;
      }

      &.second-highlight-color {
        &::after {
          border-left: 1px solid palette(baseColors, secondHighlightColor);
        }
        .work-and-studies-icon {
          background-color: palette(baseColors, secondHighlightColor);
        }
      }

      &.third-highlight-color {
        &::after {
          border-left: 1px solid palette(baseColors, thirdHighlightColor);
        }
        .work-and-studies-icon {
          background-color: palette(baseColors, thirdHighlightColor);
        }
      }

      .work-and-studies-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        left: 0;
        right: 0;
        line-height: 40px;
        text-align: center;
        z-index: 1;
        border-radius: 50%;
        color: palette(textColors, whiteColor);
        background-color: palette(baseColors, highlightColor);
        transition: background .2s ease-in-out;
      }

      .time {
        display: inline-block;
        margin-bottom: 12px;
        padding: 1px 10px;
        border-radius: 20px;
        font-size: $detail_text_smaller_size;
        font-weight: $font_bold;
        color: palette(textColors, lightGreyColor);
        background-color: #373737;
        opacity: .8;
      }

      .title {
        @include secondary-font;
        font-size: $detail_text_big_size;
        font-weight: $font_bold;
        margin: 7px 0 10px;

        .title-complement {
          position: relative;
          padding-left: 26px;
          font-size: $detail_text_regular_size;
          font-weight: $font_bold;
          color: palette(textColors, lightGreyColor);
          opacity: .8;

          &::before {
            position: absolute;
            content: '';
            width: 10px;
            height: 2px;
            top: 9px;
            left: 7px;
            background-color: palette(genericColors, whiteColor);
            opacity: .8
          }
        }
      }

      .text {
        margin: 0;
        font-size: $detail_text_regular_size;
      }
    }
  }

  &.mobile {
    width: 100%;

    .work-and-studies-list {
      .work-and-studies-entry {
        .time {
          font-size: $detail_text_mobile_smaller_size;
        }
        .title {
          font-size: $detail_text_mobile_big_size;
          .title-complement {
            font-size: $detail_text_mobile_regular_size;
          }
        }
        .text {
          font-size: $detail_text_mobile_regular_size;
          line-height: 30px;
        }
      }
    }

    &:first-of-type {
      margin-bottom: 10px;
    }
  }
}
</style>
