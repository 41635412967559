<template>
  <div class="animated-bar" :class="pickedColor"></div>
</template>

<script>
export default {
  props: {
    pickedColor: String
  }
}
</script>

<style lang="scss" scoped>
.animated-bar {
  content: '';
  display: block;
  height: 4px;
  left: 0;
  margin: 0 auto 0 auto;
  position: relative;
  right: 0;
  width: 75px;
  background-color: palette(baseColors, highlightColor);
  transition: background .2s ease-in-out;

  &::before,
  &::after {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: barAnimation;
    animation-timing-function: linear;
    background-color: palette(baseColors, mainBackground);
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 4px;
  }
  &::before {
    left: 8px;
  }

  &::after {
    left: 0;
  }

  &.second-highlight-color {
    background-color: palette(baseColors, secondHighlightColor);
  }

  &.third-highlight-color {
    background-color: palette(baseColors, thirdHighlightColor);
  }

  @keyframes barAnimation {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(75px);
        transform: translateX(75px);
    }
  }
}
</style>
