<template>
  <div class="download-button" :class="[pickedColor, { mobile: isMobile, tablet: isTablet, 'has-hover': !(isMobile || isTablet) }]">
    <a
    class="download-link"
    :class="{ mobile: isMobile, tablet: isTablet }"
    href="https://drive.google.com/file/d/1Go-8mItg4q1X2fEnvJWoODxSqEdAHAIU/view"
    target="_blank">
      {{$t('intro.downloadCV')}}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
    isTablet: Boolean,
    pickedColor: String
  }
}
</script>

<style lang="scss" scoped>
.download-button {
  width: 143px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  border: 2px solid palette(genericColors, whiteColor);
  border-radius: 30px;
  transition: all .3s ease-in-out;
  .download-link {
    @include secondary-font;
    padding: 15px;
    color: palette(textColors, whiteColor);
    transition: all .3s ease-in-out;
  }

  &.has-hover {
    &:hover {
      .download-link {
        color: palette(baseColors, highlightColor);
      }
      background-color: palette(genericColors, whiteColor);
    }
    &.second-highlight-color {
      &:hover {
        .download-link {
          color: palette(baseColors, secondHighlightColor);
        }
      }
    }

    &.third-highlight-color {
      &:hover {
        .download-link {
          color: palette(baseColors, thirdHighlightColor);
        }
      }
    }
  }

  &.second-highlight-color {
    &.mobile,
    &.tablet {
      background-color: palette(baseColors, secondHighlightColor);
    }
  }

  &.third-highlight-color {
    &.mobile,
    &.tablet {
      background-color: palette(baseColors, thirdHighlightColor);
    }
  }

  &.mobile,
  &.tablet {
    margin-top: 20px;
    border: none;
    border-radius: unset;
    background-color: palette(baseColors, highlightColor);
  }

  &.mobile {
    width: 100%;

    .download-link {
      width: 100%;
      font-size: $detail_text_regular_size;
      text-align: center;
    }
  }
}
</style>
