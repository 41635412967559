<template>
  <div id="app">
      <app-home/>
  </div>
</template>

<script>
import Home from '@/views/Home'
export default {
  components: {
    'app-home': Home
  }
}
</script>

<style lang="scss">
@import 'src/styles/main.scss';
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
</style>
