<template>
    <header class="header" :class="{ 'header-change': headerChange }">
        <img class="header-logo" :src="logo"/>
        <nav class="navigation">
          <ul class="menu-list">
            <li class="menu-option" v-for="(entry, index) in menuEntries" :key="index">
              <a class="option-link" :class="pickedColor" :href="'#' + entry.to" @click="scrollHandler($event, entry.to)">{{entry.label}}</a>
            </li>
          </ul>
          <color-picker
          :colorOptions="colorOptions"
          :pickedColor="pickedColor"
          @colorChange="$emit('colorChange', $event)"/>
        </nav>
    </header>
</template>

<script>
import ColorPicker from '@/components/generic/colorPicker/ColorPicker'
import { smoothScroll } from '@/assets/helperFunctions/helpers'
export default {
  props: {
    menuEntries: Array,
    colorOptions: Array,
    logo: String,
    headerChange: Boolean,
    pickedColor: String
  },
  components: {
    'color-picker': ColorPicker
  },
  methods: {
    scrollHandler (e, el) {
      smoothScroll(e, el)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  @include header-styles;
  padding: 20px 90px;

  .navigation {
    display: flex;
    align-items: center;
    .menu-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      .menu-option {
        padding-left: 30px;

        .option-link {
          @include secondary-font;
          padding-bottom: 5px;
          text-decoration: none;
          color: palette(textColors, whiteColor);
          font-weight: $font_light;

          &:hover {
            border-bottom: 4px solid palette(baseColors, highlightColor);
            transition: all .1s linear;
          }

          &.second-highlight-color {
            &:hover {
              border-bottom: 4px solid palette(baseColors, secondHighlightColor);
            }
          }

          &.third-highlight-color {
            &:hover {
              border-bottom: 4px solid palette(baseColors, thirdHighlightColor);
            }
          }
        }
      }
    }
  }
  &.header-change {
    background-color: #131919;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
}
</style>
