<template>
  <div class="separator-wrapper" :class="{ mobile: isMobile }"></div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean
  }
}
</script>

<style lang="scss" scoped>
.separator-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  &::after {
    content: '';
    width: 40%;
    margin: 50px auto;
    border-top: 1px solid #373737;
  }

  &.mobile {
    &::after {
      width: 60%;
    }
  }
}
</style>
